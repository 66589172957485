<template>
  <v-container fluid>
    <v-slide-y-transition mode="out-in">
      <v-row no-gutters>
        <v-col>
          <v-card class="pa-2 ma-2" outlined tile>
            <!-- toolbar -->
            <reference-tool-bar
              :title="options.title.main"
              :editedIndex="editedIndex"
              :createItemTitle="options.title.create"
              :formTitle="formTitle"
              :dialog="dialog"
              @save="save"
              @close="close"
              @open="open"
            >
              <template v-slot:formCreateEdit>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="options.editedItem.nameRu"
                    label="Наименование"
                    :hint="`Введите статус`"
                    :rules="[rules.required, rules.alfabet]"
                    autofocus
                  ></v-text-field>
                </v-flex>
                <!-- <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="options.editedItem.nameKz"
                    label="NameKz"
                    :hint="`Допускаются только буквы`"
                    :rules="[rules.required, rules.min]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="options.editedItem.nameEn"
                    label="NameEn"
                    :hint="`Допускаются только буквы`"
                    :rules="[rules.required, rules.min]"
                  ></v-text-field>
                </v-flex> -->
              </template>
            </reference-tool-bar>
            <!--Таблица с данными-->
            <reference-table
              :headers="options.headers"
              :items="options.data"
              @edit-item="editItem($event)"
              @remove-item="removeItem($event)"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
import { Vue } from "vue-property-decorator";
import { get } from "vuex-pathify";
import { mapActions } from "vuex";
import { rules } from "@/util/rules";

export default Vue.extend({
  name: "Status.vue",
  components: {
    ReferenceTable: () => import("@/components/reference/ReferenceTable.vue"),
    ReferenceToolBar: () =>
      import("@/components/reference/ReferenceToolBar.vue")
  },
  computed: {
    options: get("reference/optionsStatus"),
    formTitle() {
      return this.editedIndex === -1 ? "Создать" : "Редактировать";
    }
  },
  data() {
    return {
      rules,
      dialog: false,
      editedIndex: -1
    };
  },
  methods: {
    ...mapActions({
      fetchReference: "reference/fetchStatus"
    }),
    initialize() {
      this.fetchReference();
    },
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.options.editedItem = Object.assign({}, this.options.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    save() {
      const model = {
        id: this.options.editedItem.id,
        nameRu: this.options.editedItem.nameRu,
        nameKz: this.options.editedItem.nameKz,
        nameEn: this.options.editedItem.nameEn
      };
      if (this.editedIndex > -1) {
        this.$http.put(this.options.path.base, model).then(response => {
          if (response.status === "Ok") this.initialize();
        });
      } else {
        model.id = 0;
        this.$http.post(this.options.path.base, model).then(response => {
          if (response.status === "Ok") this.initialize();
        });
      }
      this.close(false);
    },
    editItem(item) {
      this.editedIndex = this.options.data.indexOf(item);
      this.options.editedItem = Object.assign({}, item);
      this.open();
    },
    removeItem(item) {
      console.log("Удаление через веб-интерфейс не предусмотрено");
      // if (confirm("Are you sure you want to delete this item?")) {
      //   const model = Object.assign({}, item);
      //   this.$http
      //     .delete(this.options.path.base, { data: model })
      //     .then((response) => {
      //       if (response.status === "Ok") this.initialize();
      //     });
      // }
    }
  },
  created() {
    this.initialize();
  }
});
</script>
